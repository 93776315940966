.activeUser{
    width: 30%;
    margin: 20px;
    margin-left: 30px;
    padding: 20px;
    background-color: white;
}
.chartTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.activeUserCount{
    font-size: 30px;
}

.activeUserTitle{
    margin-top: -20px;
    padding-top: -10px;;
}

.chartInfoTitle{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chartInfoView{
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
}
