.topbar{
    width: 100%;
    height: 50px;
    background-color: red;
    position: sticky;
    top: 0;
    z-index: 999;
}

.topbarWrapper{
    height: 100%;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topLeft .logo{
    height: 40px;
    margin-top: 3px;
    cursor: pointer;
}

.topRight{
    display: flex;
    align-items: center;
}

.topbarIconContainer{
    position: relative;
    cursor: pointer;
    color: white;
}

.topIconBadge{
    width: 15px;
    height: 15px;
    position: absolute;
    top: -5px;
    right: 5px;
    background-color: white;
    color: red;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
}

.icon{
    margin: 0px 5px;
}
