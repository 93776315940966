.newProduct {
  flex: 4;
  background-color: lightgray;
}

.addProductTitle{
  margin: 40px 20px;
}

.addProductForm {
  margin-top: 10px;
  margin-left: 20px;
  width: 90%;
  height: 75%;
  background-color: white;
}
.container{
  display: flex;
}

.addProductItem {
  width: 250px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 20px;
  /* margin-top: 20px; */
  padding-top: 20px;
}

.addProductItem > label {
  color: gray;
  font-weight: 600;
  margin-bottom: 10px;
}

.addProductItem > input {
  padding: 10px;
}

.addProductItem > select {
  padding: 10px;
}

.addProductButton {
  margin-top: 10px;
  padding: 7px 10px;
  margin-left: 20px;
  border: none;
  border-radius: 10px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
