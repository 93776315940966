.footer{
    width: 100%;
    height: 40px;
    background-color: white;
    /* position: sticky; */
    /* top: 0; */
    /* z-index: 999; */
}

.footerTitle{
    padding: 5px;
    align-items: center;
    text-align: center;
}