.userList {
  flex: 4;
  background-color: lightgray;
}
.userListTitle{
  margin: 40px 20px;
}
.userListTable{
  flex: 4;
  height: 70%;
  width: 90%;

}
.btn_export{
  margin: 5px;
  position:relative;
  left: 92%;
background-color: #3bb077;
}
.container{
  display: flex;
}
.userLists{
  margin: 10px 10px;
  background-color: white;
  height: 200px;
  width: 100%;
}

.userListUser {
  display: flex;
  align-items: center;
}

.userListImg {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}
.action{
  display: flex;
}
.userListEdit{
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    cursor: pointer;
    margin-right: 20px;
}

.userListDelete{
    color: red;
    cursor: pointer;
}
.disable {
  background-color: #ffcdd2; /* light red */
}

.able {
  background-color: #c8e6c9; /* light green */
}